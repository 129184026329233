import { Box, Typography, useTheme } from '@mui/material';
import { Point } from 'framer-motion';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCoordsForSearchSuggestion } from '~/api';
import SearchAddressOrZone from '~/components/SearchInput/index';
import TabsComponent from '~/components/TabsComponent';
import { Color } from '~/constants';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { Page } from '~/layouts/Page';
import { ZonesApi } from '~/redux-rtk';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AlertActions,
  AlertsSortMethod,
  AlertStatusType,
  CalendarMenu,
} from '~/redux-rtk/slices/alertSlice';
import { paramsToObject } from '~/utils/params';

import TabPanelActive from './TabPanelActive';
import TabPanelExpired from './TabPanelExpired';
import { SearchParameters, SortMethod } from './types';

// eslint-disable-next-line import/order

const tabData = [
  { id: AlertStatusType.Active, label: I18n.t('alertsList.tabLabelActive') },
  { id: AlertStatusType.Expired, label: I18n.t('alertsList.tabLabelExpired') },
];

const AlertsList = () => {
  const theme = useTheme();
  const isMobile = useIsMobileDevice();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchGeom, setSearchGeom] = useState<{
    xmax: number;
    xmin: number;
    ymax: number;
    ymin: number;
  } | null>();

  const sortMethod = useAppSelector(state => state.alert.selectedAlertSort);
  const alertCritical = useAppSelector(state => state.alert.alertCritical);
  const alertSearchTerm = useAppSelector(state => state.alert.alertSearchTerm);
  const alertStatus = useAppSelector(state => state.alert.alertStatus);
  const alertPageSelected = useAppSelector(
    state => state.alert.alertPageSelected,
  );
  const userLocation = useAppSelector(state => state.user.data.currentLocation);
  const mapCenter = useAppSelector(state => state.map.data.viewport.center);
  const dispatch = useAppDispatch();
  const expiredAlertFilters = useAppSelector(
    state => state.alert.expiredAlertFilters,
  );

  useEffect(() => {
    // The first time we load the 'Alerts List' page, update the app's "selected sort, critical, search" based on query
    // params, if applicable
    const querySortMethod = searchParams.get('sort') as AlertsSortMethod;
    const queryAlertCritical = searchParams.get('critical');
    const queryAlertSearch = searchParams.get('search');
    const queryAlertStatus = searchParams.get('status') as AlertStatusType;
    const queryZoneId = searchParams.get('zoneId');
    const queryZoneStatus = searchParams.get('zoneStatus');
    const queryFromDate = searchParams.get('fromDate');
    const queryToDate = searchParams.get('toDate');
    const querySelectedMenu = searchParams.get('selectedMenu');

    if (Object.values(AlertsSortMethod).includes(querySortMethod)) {
      dispatch(AlertActions.selectAlertSort(querySortMethod));
    }

    if (Object.values(AlertStatusType).includes(queryAlertStatus)) {
      dispatch(AlertActions.changeAlertStatus(queryAlertStatus));
    }

    if (queryAlertSearch?.length >= 0) {
      dispatch(AlertActions.updateAlertSearchTerm(queryAlertSearch));
    }

    if (queryAlertCritical === 'true') {
      dispatch(AlertActions.changeAlertCritical(true));
    }
    if (queryZoneId?.length) {
      dispatch(
        AlertActions.updateExpiredAlertFilters({
          zoneId: queryZoneId,
        }),
      );
    }
    if (queryZoneStatus) {
      dispatch(
        AlertActions.updateExpiredAlertFilters({
          zoneStatus: Number(queryZoneStatus),
        }),
      );
    }
    dispatch(
      AlertActions.updateExpiredAlertFilters({
        time: {
          fromDate: queryFromDate,
          toDate: queryToDate,
          selectedMenu: querySelectedMenu?.length
            ? querySelectedMenu
            : CalendarMenu.all,
        },
      }),
    );
  }, []);

  //separate the filters of active/expired before sending it to the api for easier processing
  const getAlertFilters = () => {
    if (alertStatus === AlertStatusType.Active) {
      //only active alert filters
      const activeFilters: any = {
        activeOnly: true,
        criticalOnly: alertCritical,
      };
      if (searchGeom) activeFilters.searchGeom = searchGeom;
      return activeFilters;
    } else {
      //only expired alert filters
      const expiredFilters: any = {};
      if (expiredAlertFilters?.zoneId) {
        expiredFilters.zoneId = expiredAlertFilters.zoneId;
      }
      if (expiredAlertFilters.zoneStatus) {
        expiredFilters.zoneStatus = expiredAlertFilters.zoneStatus;
      }
      if (expiredAlertFilters?.time?.selectedMenu !== CalendarMenu.all) {
        expiredFilters.time = expiredAlertFilters.time;
      }
      if (searchGeom) {
        expiredFilters.searchGeom = searchGeom;
      }
      return {
        ...expiredFilters,
        activeOnly: false,
        criticalOnly: alertCritical,
      };
    }
  };

  const searchCoordinatesPayLoad = (): Point => {
    if (
      sortMethod === AlertsSortMethod.Nearest &&
      alertStatus === AlertStatusType.Active
    ) {
      return {
        x: userLocation?.coordinates?.[0],
        y: userLocation?.coordinates?.[1],
      };
    }
  };

  const alertSortPayload = (): SortMethod => {
    switch (sortMethod) {
      case AlertsSortMethod.Latest:
        return {
          type: 'time',
          direction: 'DESC',
        };
      case AlertsSortMethod.County:
        return {
          type: 'county',
          direction: 'ASC',
        };
      case AlertsSortMethod.Nearest:
        return {
          type: 'proximity',
        };
    }
  };

  /**
   * @pagesettings common pagesize, pagenumber for both active and expired alerts
   * @filters common attribute for both alerts but only one will be used at a time
   * @coordinates only used in active alerts for sorting nearest (uses sort: 'proximity')
   * @sortmethod only used in active alerts for sorting
   **/
  const alertResponse = ZonesApi.useFetchAlertsQuery({
    pageSettings: {
      page: alertPageSelected,
      size: 10,
    },
    filters: getAlertFilters(),
    coordinates: searchCoordinatesPayLoad(),
    sortMethod: alertSortPayload(),
    groupedAlerts: alertStatus === AlertStatusType.Active,
  });

  const processCoordsFromSuggestion = async item => {
    if (item) {
      const geoCodeReponse = await getCoordsForSearchSuggestion(
        item,
        mapCenter,
      );

      // Blindly "pluck" the first search result and use it's extent as the search geom
      setSearchGeom(geoCodeReponse?.candidates[0].extent);
    } else {
      setSearchGeom(null);
    }
  };

  useEffect(() => {
    const searchParameters: SearchParameters = {
      ...paramsToObject(searchParams),
      critical: alertCritical.toString(),
      status: alertStatus,
    };
    if (sortMethod) {
      searchParameters.sort = sortMethod;
    }
    if (alertSearchTerm?.length >= 0) {
      searchParameters.search = alertSearchTerm;
    }
    if (expiredAlertFilters?.zoneId?.length) {
      searchParameters.zoneId = expiredAlertFilters.zoneId;
    } else {
      delete searchParameters.zoneId;
    }
    if (isNumber(expiredAlertFilters?.zoneStatus)) {
      searchParameters.zoneStatus = expiredAlertFilters.zoneStatus.toString();
    } else {
      delete searchParameters.zoneStatus;
    }
    if (expiredAlertFilters?.time?.fromDate) {
      if (expiredAlertFilters?.time?.fromDate) {
        searchParameters.fromDate = expiredAlertFilters?.time?.fromDate;
      }
      if (expiredAlertFilters?.time?.toDate) {
        searchParameters.toDate = expiredAlertFilters?.time?.toDate;
      }
      if (expiredAlertFilters?.time?.selectedMenu) {
        searchParameters.selectedMenu = expiredAlertFilters?.time?.selectedMenu;
      }
    } else {
      delete searchParameters.selectedMenu;
      delete searchParameters.fromDate;
      delete searchParameters.toDate;
    }
    setSearchParams(searchParameters);
    if (alertPageSelected >= alertResponse?.data?.totalPages) {
      dispatch(AlertActions.updateAlertPageSelected(0));
    }
  }, [
    sortMethod,
    alertCritical,
    alertSearchTerm,
    alertStatus,
    alertResponse,
    expiredAlertFilters,
  ]);

  return (
    <Page
      title={I18n.t('alertsList.title')}
      subtitle={I18n.t('alertsList.subTitle')}
      headerAlignment="left"
    >
      <Box
        sx={{
          mt: theme.spacing(2),
          maxWidth: '300px',
        }}
      >
        <SearchAddressOrZone
          placeHolderText={I18n.t('inputsPlaceholder.searchAlert')}
          onInputSelect={(value, action) => {
            processCoordsFromSuggestion(value);
          }}
          onInputValueChange={(value, action) => {}}
        />
      </Box>
      <TabsComponent
        tabsData={tabData}
        onTabChange={(event, id) => {
          // Anytime we toggle between different types of alerts, we reset the sorting to
          // time-based, because it's the only sort "expired" supports
          dispatch(AlertActions.selectAlertSort(AlertsSortMethod.Latest));
          dispatch(AlertActions.changeAlertStatus(id as AlertStatusType));
          //the page should reset to zero on tab change to start pagination from the begining
          dispatch(AlertActions.updateAlertPageSelected(0));
        }}
        activeTab={alertStatus}
      >
        <TabPanelActive
          selectedTab={AlertStatusType.Active}
          alertDetails={alertResponse.data}
          isLoading={alertResponse.isFetching}
        />
        <TabPanelExpired
          selectedTab={AlertStatusType.Expired}
          alertDetails={alertResponse.data}
          isLoading={alertResponse.isFetching}
        />
      </TabsComponent>
    </Page>
  );
};

export default AlertsList;
