import { useAppSelector } from '~/redux-rtk/';
import { LocationSelectionType } from '~/redux-rtk/slices/appSlice';

const includedLocationTypes = [
  LocationSelectionType.Position,
  LocationSelectionType.Zone,
  LocationSelectionType.Alert,
];

export const useLocationShouldHaveAddress = (useCurrentLocation: boolean) => {
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const userLocation = useAppSelector(state => state.user.data.currentLocation);

  // If we're trying to use current location and we don't have a valid one _or_ we don't have a
  // valid selection type that would show an address
  return useCurrentLocation
    ? !!userLocation?.coordinates
    : includedLocationTypes.includes(selectedLocation?.type);
};
