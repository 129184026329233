import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Grid, InputLabel, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import Icon from '~/components/Icon';
import { TickIcon } from '~/components/Icon/TickIcon';
import { Color } from '~/constants';
import { ALERTDATEFORMAT } from '~/constants/common';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AlertActions,
  CalendarMenu,
  TimeFilter,
} from '~/redux-rtk/slices/alertSlice';
import { theme } from '~/theme';

import PersonalisedPeriod from './PersonalisedPeriod';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [calenderContainer, setCalenderContainer] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const updateFilters = (value: TimeFilter) => {
    dispatch(AlertActions.updateExpiredAlertFilters({ time: value }));
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (
    menuType: CalendarMenu,
    fromDate?: any,
    toDate?: any,
  ) => {
    updateFilters({
      toDate: toDate,
      selectedMenu: menuType,
      fromDate: fromDate,
    });
    setAnchorEl(null);
  };

  const calendarSettings = useAppSelector(
    state => state.alert.expiredAlertFilters.time,
  );

  const today = () => {
    return dayjs().format(ALERTDATEFORMAT);
  };

  const lastSevenDays = () => {
    return dayjs().subtract(7, 'day').format(ALERTDATEFORMAT);
  };

  const lastThirtyDays = () => {
    return dayjs().subtract(30, 'day').format(ALERTDATEFORMAT);
  };

  const menuItems = [
    {
      id: CalendarMenu.all,
      label: 'alertsList.all',
      action: () => handleMenuClick(CalendarMenu.all),
    },
    {
      id: CalendarMenu.today,
      label: 'alertsList.today',
      action: () => handleMenuClick(CalendarMenu.today, today()),
    },
    {
      id: CalendarMenu.lastSevenDays,
      label: 'alertsList.lastSevenDays',
      action: () =>
        handleMenuClick(CalendarMenu.lastSevenDays, lastSevenDays(), today()),
    },
    {
      id: CalendarMenu.lastThirtyDays,
      label: 'alertsList.lastThirtyDays',
      action: () =>
        handleMenuClick(CalendarMenu.lastThirtyDays, lastThirtyDays(), today()),
    },
  ];

  return (
    <Grid container width={'max-content'}>
      <Grid item>
        <InputLabel shrink={false} htmlFor={'autocomplete'}>
          <Typography variant="body2" color={'gray'}>
            {I18n.t('alertsList.filterByDate')}
          </Typography>
        </InputLabel>
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="outlined"
            startIcon={
              <Box sx={{ marginLeft: 1 }}>
                <Icon name="Calendar" color="black" />
              </Box>
            }
            sx={{
              borderRadius: theme.spacing(0.5),
              color: Color.DarkBlue,
              borderColor: Color.Grey200,
              minWidth: '144px',
              minHeight: '36px',
              display: 'flex',
              justifyContent: 'flex-start',
              padding: theme.spacing(0.5),
              '&:hover': {
                borderColor: Color.DarkBlue,
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {calendarSettings?.selectedMenu}
            </Typography>
            <KeyboardArrowDownIcon
              sx={{ marginLeft: 'auto', color: Color.Grey600 }}
            />
          </Button>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'menu-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                border: `1px solid ${Color.Grey200}`,
                borderRadius: '5px',
                marginTop: 0.5,
                minWidth: '218px',
              },
            }}
          >
            {menuItems.map(({ id, label, action }) => {
              const isSelected = calendarSettings?.selectedMenu === id;
              return (
                <MenuItem
                  key={id}
                  onClick={action}
                  sx={{
                    borderBottom: `1px solid ${Color.AntiFlashWhite}`,
                    padding: theme.spacing(1, 2),
                    '&:hover': {
                      backgroundColor: Color.Grey50,
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: isSelected ? 600 : 400 }}
                  >
                    {I18n.t(label)}
                  </Typography>

                  {isSelected && (
                    <TickIcon sx={{ position: 'absolute', right: 4 }} />
                  )}
                </MenuItem>
              );
            })}
            <MenuItem
              sx={{
                backgroundColor: calenderContainer
                  ? `${Color.LeafGreen}1A`
                  : 'transparent',
              }}
            >
              <PersonalisedPeriod
                calenderContainer={calenderContainer}
                updateCalenderContainer={setCalenderContainer}
                handleClose={handleClose}
              />
              {calendarSettings?.selectedMenu ===
                CalendarMenu.personalisedPeriod && (
                <TickIcon sx={{ position: 'absolute', right: 4 }} />
              )}
            </MenuItem>
          </Menu>
        </div>
      </Grid>
    </Grid>
  );
}
