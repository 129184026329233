import { PointsOfInterestContainer } from '~/components/ZoneDetails/PointsOfInterest';
import { Icon } from '../../UI/Icon';
import { Title } from '../shared/Title';
import { I18n } from '../../../i18n/index';
import { Card } from '../../../layouts/Card/index';
import { ZoneWeather } from '~/components/ZoneDetails/MiscZoneInfo/ZoneWeather';
import { Zone } from '../../Map/types';
import { H2Title } from '../shared/Title/H2Title';
import { Position } from '@turf/turf';
interface ZoneStatusProps {
  zone: Zone;
  zoneId: string;
  location?: Position
}
const CurrentLocationDetailsCard = ({ zoneId, zone, location }: ZoneStatusProps) => {
  return (
    <>
      <PointsOfInterestContainer location={location}/>
      <Card hasShadow={false} backgroundColor="grey">
        <H2Title
          title={I18n.t('zoneDetails.locationInformation')}
          icon={<Icon size={18} name="zone-info" isDecorative={true} />}
        />
        <Title
          title={I18n.t('zoneDetails.currentWeather')}
        />
        <ZoneWeather sx={{ mt: 2 }} location={[zone.latitude, zone.longitude]} />
      </Card>
    </>
  )
}
export default CurrentLocationDetailsCard;