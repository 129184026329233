import { Box, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';

import { ReactComponent as NoAlertIcon } from 'assets/icons/no-alerts.svg';
import { ReactComponent as NoLocationFoundIcon } from 'assets/icons/no-location-found.svg';
import SearchZoneInput from 'containers/SearchZoneInput';
import { isFollowZoneEnabled, isMobileDownloadEnabled } from 'flags';
import { useIsMobileDevice } from 'hooks';
import { I18n } from 'i18n';
import { Card } from 'layouts/Card';
import { Content } from 'layouts/Content';
import { Page } from 'layouts/Page';
import {
  useAppDispatch,
  useAppSelector,
  useFetchZoneDetailsWithCoordsQuery,
  UserActions,
  ZonesApi,
} from 'redux-rtk';
import {
  AppActions,
  AppSetupSteps,
  LocationSelectionType,
} from 'redux-rtk/slices/appSlice';
import {
  LocalStorageFollowedZone,
  LocalStorageKeys,
  LocationOrigin,
} from 'types';
import { parseBBOX } from 'utils';
import { CommonMessageCard } from '~/components/CommonMessageCard';
import { Divider } from '~/components/Divider';
import GPSPermissionCard from '~/components/GPSPermissionCard';
import { MapSearchIcon } from '~/components/Icon/MapSearchIcon';
import { EvacAlertCard } from '~/components/Map/MapCards/ZoneCard';
import { MapPreview } from '~/components/MapPreview';
import { DownloadAppCTA } from '~/components/Marketing/DownloadAppCTA';
import { SelectedLocation } from '~/components/SelectedLocation';
import { Loader } from '~/components/UI';
import { ModalsContext, ModalsContextIds } from '~/context';
import { useIsFocused } from '~/hooks/useIsFocused';
import { theme } from '~/theme';

import { useLocationShouldHaveAddress } from '~/hooks/useLocation';
import CurrentLocationDetailsCard from '../../components/ZoneDetails/MiscZoneInfo/CurrentLocationDetailsCard';
import { InaccuracyInMetersForWarning } from '../../constants';
import { CommonAlertCard } from '../AlertsInLocation/CommonAlertCards';
import { AccuracyCard } from './AccuracyCard';

export const Search = () => {
  const dispatch = useAppDispatch();

  const preferredOrigin = useReadLocalStorage<LocationOrigin | null>(
    LocalStorageKeys.PreferredOrigin,
  );

  const isMobile = useIsMobileDevice();

  const [followedZones] = useLocalStorage<LocalStorageFollowedZone[]>(
    LocalStorageKeys.FollowedZones,
    [],
  );
  const contextValue = useContext(ModalsContext);

  const [, setPrefferedOrigin] = useLocalStorage<LocationOrigin>(
    LocalStorageKeys.PreferredOrigin,
    LocationOrigin.Pending,
  );

  const handleAllow = useCallback(() => {
    setPrefferedOrigin(LocationOrigin.Browser);
    contextValue.setCurrentOpenedModal(ModalsContextIds.GpsPermission);
  }, []);

  const userLocation = useAppSelector(state => state.user.data.currentLocation);
  const visibleOnboardingModal = useAppSelector(
    state => state.app.onboardingModalIsVisible,
  );
  const completedAppSetupSteps = useAppSelector(
    state => state.app.data.completedAppSetupSteps,
  );
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  // Fetch zone details, but only if we have a user location that came from the browser
  const isAbleToMakeZoneDetailsQuery =
    userLocation.coordinates && userLocation.origin === LocationOrigin.Browser;

  const {
    data: userZoneDetails,
    isFetching,
    isError,
  } = useFetchZoneDetailsWithCoordsQuery(userLocation.coordinates, {
    skip: !isAbleToMakeZoneDetailsQuery,
  });

  const alertDetails = ZonesApi.useFetchAlertsQuery(
    {
      pageSettings: {
        page: 0,
        size: 1000,
      },
      filters: {
        searchGeom: userLocation?.coordinates && {
          xmax: userLocation?.coordinates[1],
          xmin: userLocation?.coordinates[1],
          ymax: userLocation?.coordinates[0],
          ymin: userLocation?.coordinates[0],
        },
        activeOnly: true,
      },
      sortMethod: {
        type: 'time',
        direction: 'DESC',
      },
    },
    { skip: !isAbleToMakeZoneDetailsQuery || !userLocation?.coordinates },
  );

  const filteredALERTalerts = useMemo(() => {
    if (!alertDetails?.data?.content?.length) return;
    return {
      ...alertDetails,
      data: {
        ...alertDetails?.data,
        content: alertDetails?.data?.content?.filter(
          alert => alert?.source === 'ALERT',
        ),
      },
    };
  }, [alertDetails]);

  useEffect(() => {
    // if we have the users zone info, select zone as the initial location
    // note: on mobile we always want to come back to the users zone
    if (userZoneDetails?.zone)
      dispatch(UserActions.setUserZoneDetails(userZoneDetails));
    if (
      userZoneDetails?.zone &&
      ((userLocation.origin === LocationOrigin.Browser &&
        !completedAppSetupSteps.includes(
          AppSetupSteps.SetInitialLocationSelection,
        )) ||
        (isMobile &&
          selectedLocation?.id !== userZoneDetails?.zone.identifier &&
          !selectedLocation?.position))
    ) {
      const {
        latitude,
        longitude,
        identifier,
        bbox: bboxString,
      } = userZoneDetails.zone;

      dispatch(
        AppActions.setInitialLocation({
          position: [latitude, longitude],
          type: LocationSelectionType.Zone,
          details: userZoneDetails.zone,
          id: identifier,
          bbox: parseBBOX(bboxString),
          settings: {
            hasPopup: false,
            hasFlyToAnimation: false,
          },
        }),
      );
    }
  }, [
    userZoneDetails?.zone,
    isMobile,
    selectedLocation,
    userLocation,
    completedAppSetupSteps,
    dispatch,
  ]);

  useEffect(() => {
    // if we manage to get users coords via geotargetly but have no zone info
    // pan map to users approximate location
    if (
      userLocation.origin === LocationOrigin.Geotargetly &&
      !completedAppSetupSteps.includes(
        AppSetupSteps.SetInitialLocationSelection,
      )
    ) {
      dispatch(
        AppActions.setInitialLocation({
          position: userLocation.coordinates,
          type: LocationSelectionType.Position,
        }),
      );
    }
  }, [dispatch, userLocation, completedAppSetupSteps]);

  // Note: see Map.tsx for logic on setting initial location from lat / lon & zoom

  const isFocused = useIsFocused();

  const isLoading =
    (!preferredOrigin ||
      userLocation.origin === LocationOrigin.Pending ||
      isFetching) &&
    !visibleOnboardingModal &&
    !isFocused;

  const isLocationWithAlert =
    userZoneDetails || alertDetails?.data?.content?.length > 0;

  const isNormalStatusWithNoAlerts =
    userZoneDetails?.zone?.status?.toLowerCase() === 'normal' &&
    (alertDetails?.data?.content?.length ?? 0) === 0;

  const hasNoZoneOrAlerts =
    !userZoneDetails && alertDetails?.data?.content?.length < 1;

  const isNotBrowserOrigin = preferredOrigin !== LocationOrigin.Browser;

  const isBrowserOriginFailed =
    preferredOrigin === LocationOrigin.Browser &&
    userLocation.origin === LocationOrigin.Geotargetly;

  const showMapPreview = userLocation.coordinates || isFocused;

  const locationShouldHaveAddress = useLocationShouldHaveAddress(true);

  return (
    <Page title={I18n.t('search.title')} subtitle={I18n.t('search.subtitle')}>
      <SearchZoneInput />
      {isLoading ? (
        <Loader />
      ) : (
        <Content sx={{ position: 'relative' }}>
          <Divider label={I18n.t('search.myCurrentLocation')} />
          {locationShouldHaveAddress && (
            <SelectedLocation
              hideTitle
              isCurrentLocation
              zoneId={userZoneDetails?.zone?.identifier}
              greyBackground
            />
          )}
          {isLocationWithAlert && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {isNormalStatusWithNoAlerts ? (
                  <>
                    <CommonMessageCard
                      title={I18n.t('search.noAlerts')}
                      body={I18n.t('search.noActiveIncidents')}
                      icon={<NoAlertIcon aria-hidden="true" role="img" />}
                    />
                    {userLocation?.accuracyInMeters && (
                      <AccuracyCard
                        isAccurate={
                          userLocation.accuracyInMeters <
                          InaccuracyInMetersForWarning
                        }
                      />
                    )}
                    {showMapPreview && <MapPreview mapId="searchPreview" />}
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        flexDirection: 'column',
                      }}
                    >
                      <CommonAlertCard
                        evacDetails={{
                          ...userZoneDetails,
                          shouldShowEvacData:
                            !!userZoneDetails &&
                            userZoneDetails?.zone?.status !== 'Normal',
                          zoneId: userZoneDetails?.zone?.identifier,
                        }}
                        alertDetails={{
                          ...filteredALERTalerts,
                          shouldShowAlertData:
                            filteredALERTalerts?.data?.content?.length > 0,
                        }}
                      />
                    </Box>
                    {userLocation?.accuracyInMeters && (
                      <AccuracyCard
                        isAccurate={
                          userLocation.accuracyInMeters <
                          InaccuracyInMetersForWarning
                        }
                      />
                    )}
                    {showMapPreview && <MapPreview mapId="searchPreview" />}
                    {userZoneDetails?.zone && (
                      <Box sx={{ marginTop: theme.spacing(2) }}>
                        <CurrentLocationDetailsCard
                          zoneId={userZoneDetails?.zone?.identifier}
                          zone={userZoneDetails?.zone}
                          location={userLocation.coordinates}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          )}

          {!isLocationWithAlert && hasNoZoneOrAlerts && (
            <>
              <CommonMessageCard
                title={I18n.t('search.noAlerts')}
                body={I18n.t('search.noActiveIncidents')}
                icon={<NoAlertIcon aria-hidden="true" role="img" />}
              />
              {userLocation?.accuracyInMeters && (
                <AccuracyCard
                  isAccurate={
                    userLocation.accuracyInMeters < InaccuracyInMetersForWarning
                  }
                />
              )}
              {showMapPreview && <MapPreview mapId="searchPreview" />}
            </>
          )}
          {isNotBrowserOrigin && (
            <>
              <GPSPermissionCard />
              <Typography
                variant="body2"
                sx={{
                  gap: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <MapSearchIcon role="img" aria-hidden="true" />
                {I18n.t('search.selectALocation')}
              </Typography>
              {showMapPreview && <MapPreview mapId="searchPreview" />}
            </>
          )}
          {/* this case happens when the user allowed browser gps but
            for some reason we failed to get the coords (think timeout, etc) */}
          {isBrowserOriginFailed && (
            <>
              <CommonMessageCard
                title={I18n.t('gpsPermissionModal.title')}
                body={I18n.t('search.enableLocationAccess')}
                buttonLabel={I18n.t('search.shareLocation')}
                icon={
                  <NoLocationFoundIcon
                    width="36px"
                    height="36px"
                    aria-hidden="true"
                    role="img"
                  />
                }
                onClick={handleAllow}
              />
              {showMapPreview && <MapPreview mapId="searchPreview" />}
            </>
          )}

          {isError && (
            <>
              <CommonMessageCard
                title={I18n.t('search.statusUnknown')}
                body={I18n.t('search.statusUnknownDescription')}
                icon={
                  <NoLocationFoundIcon
                    width="36px"
                    height="36px"
                    aria-hidden="true"
                    role="img"
                  />
                }
              />

              {userLocation?.accuracyInMeters && (
                <AccuracyCard
                  isAccurate={
                    userLocation.accuracyInMeters < InaccuracyInMetersForWarning
                  }
                />
              )}
              {showMapPreview && <MapPreview mapId="searchPreview" />}
            </>
          )}

          {followedZones?.length > 0 && (
            <>
              <Divider
                label={I18n.t('search.myFollowedZones')}
                sx={{ mt: 3 }}
              />
              {followedZones.map(el => (
                <EvacAlertCard
                  key={el.zoneId}
                  zoneId={el.zoneId}
                  isFollowButtonVisible={isFollowZoneEnabled}
                />
              ))}
            </>
          )}
        </Content>
      )}

      {isMobileDownloadEnabled && (
        <Box sx={{ backgroundColor: 'grey.50', p: 2, mx: -3 }}>
          <Card
            hasShadow={false}
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DownloadAppCTA />
          </Card>
        </Box>
      )}
    </Page>
  );
};
