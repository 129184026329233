import { useCallback, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  trackUserSearchedAddressOutsideZone,
  trackUserSearchedAddressWithinZone,
  trackUserSearchedZoneById,
} from 'analytics/fns';
import { getZoneIdentifierFromSearchResult, isZoneId } from 'utils';
import { isFetchBaseQueryError } from 'utils/typesUtils';
import SearchAddressOrZone from '~/components/SearchInput';
import { I18n } from '~/i18n';

import { getCoordsForSearchSuggestion } from '../../api';
import { NotFound } from '../../constants';
import {
  useAppDispatch,
  useAppSelector,
  useFetchZoneDetailsQuery,
  useFetchZoneDetailsWithCoordsQuery,
} from '../../redux-rtk';
import {
  AppActions,
  LocationSelectionType,
} from '../../redux-rtk/slices/appSlice';

const SearchZoneInput = () => {
  const [selectedCoords, setSelectedCoords] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedSearchTerm, setSelectedSearchTerm] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get the map's lng / lat as a comma-separated string. We use this as a bias when searching, and
  // also as a cache option for the AsyncSelect, so that it forces a refresh when these change
  const mapCenter = useAppSelector(state => state.map.data.viewport.center);

  const handleToFieldInputChange = (value, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      if (value.trim() === '') {
        setSelectedCoords(null);
        setSelectedZoneId(null);
      }
    }
  };

  const onChange = useCallback(async (item, action) => {
    if(!item?.value) return;
    if (isZoneId(item?.value)) {
      setSelectedZoneId(item?.value);
      // If the search looks like a zone, we jump straight to it without doing any additional querying
      trackUserSearchedZoneById(item?.value);
      return;
    }
    // Using the selected item & the map's current center, get the actual coordinates for this item
    // NOTE that the `mapCenter` is expected to have not changed since the user last typed something
    // in to the search box & we re-queried the suggestions
    // I believe this to be safe, because the way our application works, if the user started
    // interacting with the map (or anything else on the page which might cause the map's center to
    // change), the search box will hide, and the user would need to re-type something to trigger
    // fresh suggestion results with a new map center anyways.
    const geoCodeReponse = await getCoordsForSearchSuggestion(item, mapCenter);

    const coords = geoCodeReponse
      ? [
          geoCodeReponse?.candidates[0]?.location?.y,
          geoCodeReponse?.candidates[0]?.location?.x,
        ]
      : null;

    if (coords) {
      dispatch(
        AppActions.selectLocation({
          type: LocationSelectionType.Position,
          position: [...coords],
        }),
      );
    }

    //setting 'search':'true' enables detection of navigation from '/search' > '/location'
    navigate({
      pathname: '/location',
      search: createSearchParams({
        search: 'true',
      }).toString(),
    });
  }, []);

  const {
    data: zoneDetails,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useFetchZoneDetailsWithCoordsQuery(selectedCoords, {
    skip: !selectedCoords,
  });

  const {
    data: zoneIdDetails,
    isError: zoneIdIsError,
    isSuccess: zoneIdIsSuccess,
    isLoading: zoneIdIsLoading,
  } = useFetchZoneDetailsQuery(selectedZoneId?.toUpperCase(), {
    skip: !selectedZoneId,
  });

  useEffect(() => {
    if (isSuccess && zoneDetails) {
      trackUserSearchedAddressWithinZone(zoneDetails.zone?.identifier);
      const searchTerm = encodeURI(selectedSearchTerm);
      return navigate(
        `/zones/${zoneDetails.zone?.identifier}?searchTerm=${searchTerm}&coords=${selectedCoords}`,
      );
    }
    if (zoneIdIsSuccess && zoneIdDetails) {
      trackUserSearchedZoneById(selectedZoneId);
      const searchTerm = encodeURI(selectedZoneId.toUpperCase());
      return navigate(
        `/zones/${getZoneIdentifierFromSearchResult(
          zoneIdDetails,
          selectedZoneId,
        )}?searchTerm=${searchTerm}`,
      );
    }
  }, [isSuccess, zoneDetails, zoneIdIsSuccess, zoneIdDetails]);

  useEffect(() => {
    if (isError || !zoneDetails) {
      if (
        (isFetchBaseQueryError(error) || !zoneDetails) &&
        selectedSearchTerm
      ) {
        const _error = error as any;
        // TODO: Handle error with error id not error message
        if (_error?.data?.message === "Zone doesn't exist" || !zoneDetails) {
          trackUserSearchedAddressOutsideZone();
        }
      }
      if (selectedCoords) {
        dispatch(
          AppActions.selectLocation({
            type: LocationSelectionType.Position,
            position: [...selectedCoords],
          }),
        );
      }
    }

    if (
      selectedSearchTerm &&
      ((zoneIdIsError && !zoneIdIsLoading) || (isError && !isLoading))
    ) {
      const searchTerm = encodeURI(selectedSearchTerm);
      return navigate(`/zones/${NotFound}?searchTerm=${searchTerm}`);
    }

    if (isError || zoneIdIsError) {
      // Something went wrong; reset state to nulls
      setSelectedCoords(null);
      setSelectedZoneId(null);
      setSelectedSearchTerm(null);
    }
  }, [
    isError,
    zoneDetails,
    selectedSearchTerm,
    isLoading,
    zoneIdIsError,
    zoneIdDetails,
    zoneIdIsLoading,
  ]);

  return (
    <SearchAddressOrZone
      placeHolderText={I18n.t('inputsPlaceholder.searchAddressOrZone')}
      onInputSelect={onChange}
      onInputValueChange={handleToFieldInputChange}
    />
  );
};

export default SearchZoneInput;
