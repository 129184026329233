import { Box, Tooltip, Typography } from '@mui/material';

import { theme } from '~/theme';
import { PropsWithSx } from '../../../../utils';
import Icon from '../../../Icon';
import { Zone } from '../../types';

interface ZoneStatusProps {
  zone: Zone;
}

export const ZoneStatus = ({
  zone,
  sx,
  ...rest
}: PropsWithSx<ZoneStatusProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: 1,
        flexDirection: 'row',
        ...sx,
      }}
      {...rest}
    >
      <Icon size={20} color={zone.statusColor} name={'StatusDot'} />

      <Box
        sx={{
          pl: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body1" fontWeight={'600'}>
          {zone.status}
        </Typography>
        <Tooltip title={zone?.statusReason}>
          <Typography
            variant="body2"
            sx={{
              marginTop: theme.spacing(1),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {zone?.statusReason}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};
