import { useNavigate } from 'react-router-dom';

import { Page } from '~/layouts/Page';
import { useAppDispatch, useAppSelector } from '~/redux-rtk';
import { AppActions } from '~/redux-rtk/slices/appSlice';
import AlertDetailView from '../AlertsList/AlertDetailView/AlertDetailView';

interface SelectDataType {
  value: string;
  label: string;
}

const Alert = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const alertData = useAppSelector(state => state.alert.selectedAlertData);
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const hasPopup = useAppSelector(state => state.app.data.hasPopUp);

  const shouldDisplayBackButton = !(
    alertData?.navigatedFrom === 'location' && alertData?.source === 'EVAC'
  );

  const handleBackButtonClick = () => {
    if (alertData?.navigatedFrom !== 'location') {
      return navigate('/alerts');
    }

    if (selectedLocation?.prevPosition) {
      if (!hasPopup) {
        dispatch(
          AppActions.selectLocation({
            ...selectedLocation,
            settings: { hasPopup: true },
          }),
        );
      }
      return navigate(-1);
    }

    navigate('/search');
  };

  return (
    <Page
      hasBackButton={shouldDisplayBackButton}
      onBackButtonPress={handleBackButtonClick}
    >
      <AlertDetailView />
    </Page>
  );
};

export default Alert;
