import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { InputLabel, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { Color } from '~/constants';
import { ALERTDATEFORMAT } from '~/constants/common';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AlertActions,
  CalendarMenu,
  TimeFilter,
} from '~/redux-rtk/slices/alertSlice';

const LOCALDATEFORMAT = 'YYYY-MM-DD';

export default function PersonalisedPeriod({
  calenderContainer,
  updateCalenderContainer,
  handleClose,
}: {
  calenderContainer: null | HTMLElement;
  updateCalenderContainer: (data: null | HTMLElement) => void;
  handleClose: () => void;
}) {
  const open = Boolean(calenderContainer);

  const calendarSettings = useAppSelector(
    state => state.alert.expiredAlertFilters.time,
  );

  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [formErrors, setFormErrors] = useState({ from: '', to: '' });
  const updateFilters = (value: TimeFilter) => {
    dispatch(AlertActions.updateExpiredAlertFilters({ time: value }));
  };

  useEffect(() => {
    if (calendarSettings.selectedMenu === CalendarMenu.personalisedPeriod) {
      setDateRange({
        from: dayjs(calendarSettings?.fromDate).format(LOCALDATEFORMAT),
        to: dayjs(calendarSettings?.toDate).format(LOCALDATEFORMAT),
      });
    }
  }, [calendarSettings]);

  useEffect(() => {
    setFormErrors({ from: '', to: '' });
  }, [dateRange]);

  const menuItemStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  };

  const handleApply = () => {
    const newErrors = { from: '', to: '' };
    if (!dateRange.from) {
      newErrors.from = I18n.t('alertsList.required');
    }
    if (!dateRange.to) {
      newErrors.to = I18n.t('alertsList.required');
    }
    if (dayjs(dateRange.to).isBefore(dayjs(dateRange.from))) {
      newErrors.to = I18n.t('alertsList.dateRangeError');
    }
    setFormErrors(newErrors);
    if (newErrors.to || newErrors.from) return;
    updateFilters({
      fromDate: dayjs(dateRange.from).format(ALERTDATEFORMAT),
      selectedMenu: CalendarMenu.personalisedPeriod,
      toDate: dayjs(dateRange.to).format(ALERTDATEFORMAT),
    });
    updateCalenderContainer(null);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-haspopup="true"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          updateCalenderContainer(e.currentTarget)
        }
        endIcon={<KeyboardArrowRight sx={{ color: 'black' }} />}
        variant="text"
        sx={{
          margin: 0,
          padding: 0,
          color: 'black',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight:
              calendarSettings?.selectedMenu === CalendarMenu.personalisedPeriod
                ? 600
                : 400,
          }}
        >
          {I18n.t('alertsList.personalisedPeriod')}
        </Typography>
      </Button>
      <Menu
        id="menu"
        anchorEl={calenderContainer}
        open={open}
        onClose={() => updateCalenderContainer(null)}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          marginLeft: 6,
          '& .MuiPaper-root': {
            border: `1px solid ${Color.Grey200}`,
            borderRadius: '5px',
          },
          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        <MenuItem sx={menuItemStyles}>
          <InputLabel> {I18n.t('alertsList.from')}</InputLabel>
          <TextField
            required
            error={!!formErrors.from}
            id=""
            onChange={event => {
              setDateRange({ ...dateRange, from: event.target.value });
            }}
            helperText={formErrors.from}
            type="date"
            value={dateRange.from}
            sx={{
              '& .MuiInputBase-root': {
                minHeight: '0',
              },
            }}
          />
        </MenuItem>
        <MenuItem sx={menuItemStyles}>
          <InputLabel> {I18n.t('alertsList.to')}</InputLabel>
          <TextField
            required
            error={!!formErrors.to}
            onChange={event => {
              setDateRange({ ...dateRange, to: event.target.value });
            }}
            value={dateRange.to}
            helperText={formErrors.to}
            type="date"
            sx={{
              '& .MuiInputBase-root': {
                minHeight: '0',
              },
            }}
          />
        </MenuItem>
        <MenuItem sx={{ '&:hover': { backgroundColor: 'white' } }}>
          <Button
            size="small"
            id="button"
            sx={{
              marginLeft: 'auto',
              fontSize: '14px',
              color:
                dateRange.from && dateRange.to
                  ? Color.LeafGreen
                  : Color.DarkBlue,
            }}
            onClick={handleApply}
          >
            {I18n.t('alertsList.apply')}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
