import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { polygon } from '@turf/helpers';
import { Position } from 'geojson';

import {
  AlertCardData,
  AlertGroup,
  AlertPayLoadType,
  GroupedAlertDetails,
  SortMethod,
} from '~/pages/AlertsList/types';
import { round } from '~/utils';
import {
  filterResponseData,
  getBboxSquareAroundCoordinate,
  getBufferedPolygonForQuery,
  getDateFilters,
  reSortResponseData,
  transformToAlertCardData,
} from '~/utils/alertsUtils';

import { BaseZonehavenApi } from './baseZonehavenApi';
import { Hazard, ZoneDetails } from '../../components/Map/types';
import { CacheTimeoutPeriod } from '../../constants';
import { HazardsSortMethod, LocationSelectionType } from '../slices/appSlice';

interface PointOfInterest {
  name: string;
  address: string;
  type: LocationSelectionType;
}

interface EvacuationPointsResponse {
  arrivalPoints: PointOfInterest[];
}

interface TrafficControlPointsResponse {
  trafficControlPoints: PointOfInterest[];
}

interface HazardDataSlice {
  hazards: Hazard[];
  nextOffset: number;
  lastPage: boolean;
}

interface ZoneLink {
  name: string;
  url: string;
  note?: string;
  image?: string;
}

type HazardId = string;
type ZoneName = string;
type ZoneId = string;

enum ZonesApiCacheTags {
  ZoneDetails = 'zoneDetails',
  TrafficControlPoints = 'trafficControlPoints',
  EvacuationPoints = 'evacuationPoints',
  EvacFetchZone = 'evacFetchZone',
  GemFetchAlert = 'gemFetchAlert',
}

const ZonesApiWithTags = BaseZonehavenApi.enhanceEndpoints({
  addTagTypes: [
    ZonesApiCacheTags.ZoneDetails,
    ZonesApiCacheTags.EvacuationPoints,
    ZonesApiCacheTags.TrafficControlPoints,
    ZonesApiCacheTags.EvacFetchZone,
    ZonesApiCacheTags.GemFetchAlert,
  ],
});

export const ZonesApi = ZonesApiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    fetchZoneDetails: builder.query<
      ZoneDetails | undefined,
      Position | ZoneName
    >({
      query: params => {
        return `zone?zone_id=${params}`;
      },
      providesTags: [ZonesApiCacheTags.ZoneDetails],
    }),
    fetchZoneDetailsWithCoords: builder.query<
      ZoneDetails | undefined,
      Position | ZoneName
    >({
      //Overriding default baseQuery and adding inline baseQuery to handle error response
      async queryFn(params, api, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `${process.env.REACT_APP_API_BASE_URL}zone?coordinates=${params[1]},${params[0]}`,
          method: 'GET',
        });
        if (response.error?.status === 404) {
          // don't refetch on 404
          return { data: response.data as ZoneDetails };
        }
        if (response.error) {
          // but refetch on another error
          return { error: response.error };
        }
        return { data: response.data as ZoneDetails };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { zone },
          } = await queryFulfilled;
          //Update cache for zone.identifier with the retrieved zone data
          const patchResult = dispatch(
            ZonesApi.util.updateQueryData(
              'fetchZoneDetails',
              zone.identifier,
              draft => {
                Object.assign(draft, zone);
              },
            ),
          );
        } catch {}
      },
      providesTags: [ZonesApiCacheTags.ZoneDetails],
    }),
    fetchHazards: builder.query<
      HazardDataSlice,
      {
        location?: Position;
        offset: number;
        sortBy: HazardsSortMethod;
        name?: string;
      }
    >({
      query: params => {
        const urlParams = [];

        urlParams.push(`sortby=${params.sortBy}`);
        urlParams.push(`offset=${params.offset}`);
        urlParams.push(`name=${params.name}`);

        if (params.sortBy === HazardsSortMethod.Distance && params.location) {
          urlParams.push(
            `location=${round(params.location[1], 2)},${round(
              params.location[0],
              2,
            )}`,
          );
        }

        return `/hazards?${urlParams.join('&')}`;
      },
    }),
    fetchHazardDetails: builder.query<Hazard, HazardId>({
      query: hazardId => {
        return `/hazards/${hazardId}`;
      },
    }),
    fetchLocationEvacuationPoints: builder.query<
      EvacuationPointsResponse,
      Position
    >({
      query: bbox =>
        `/arrivalpoints/${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`,
      providesTags: [ZonesApiCacheTags.EvacuationPoints],
    }),
    fetchLocationTrafficControlPoints: builder.query<
      TrafficControlPointsResponse,
      Position
    >({
      query: bbox =>
        `/trafficcontrolpoints/${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`,
      providesTags: [ZonesApiCacheTags.TrafficControlPoints],
    }),
    fetchZoneEvacuationPoints: builder.query<EvacuationPointsResponse, ZoneId>({
      query: zoneId => `/zone/${zoneId}/arrivalpoints`,
      providesTags: [ZonesApiCacheTags.EvacuationPoints],
    }),
    fetchZoneTrafficControlPoints: builder.query<
      TrafficControlPointsResponse,
      ZoneId
    >({
      query: zoneId => `/zone/${zoneId}/trafficcontrolpoints`,
      providesTags: [ZonesApiCacheTags.TrafficControlPoints],
    }),
    fetchWeather: builder.query<any, Position>({
      query: location => `/weather/${location[0]},${location[1]}`,
    }),
    fetchZoneLinks: builder.query<ZoneLink[], ZoneId>({
      query: zoneId => `/zone/${zoneId}/links`,
      transformResponse: (response: { links: ZoneLink[] }) => {
        return response.links;
      },
    }),
    fetchAlerts: builder.query<any, any>({
      async queryFn(params: AlertPayLoadType, api, extraOptions, baseQuery) {
        const { page, size } = params.pageSettings;
        const queryString = new URLSearchParams();
        const { coordinates } = params;

        // sort options
        let sort: SortMethod = params.sortMethod;

        // We behave slightly differently for point based searches before and after querying so need
        // to track it
        let pointBasedSearch = false;

        //for search using coordinates, sort type should be `proximity`
        if (coordinates?.x) {
          sort = { type: 'proximity' };
        }

        //filter options
        let filters = Object.keys(params.filters).reduce((filterObjs, key) => {
          // if criticalOnly is false, no need to send it to the payload
          if (key === 'criticalOnly' && !params.filters.criticalOnly)
            return [...filterObjs];
          if (key === 'time')
            return [...filterObjs, ...getDateFilters(params.filters.time)];
          if (key === 'zoneId') {
            return [
              ...filterObjs,
              {
                property: key,
                operator: 'like',
                value: params.filters[key],
              },
            ];
          }
          if (key === 'campaign_id')
            return [
              ...filterObjs,
              {
                property: key,
                operator: 'in',
                value: params.filters[key],
              },
            ];
          if (key === 'searchGeom') {
            return [
              ...filterObjs,
              {
                property: key,
                operator: 'eq',
                value: getBufferedPolygonForQuery(params.filters['searchGeom']),
              },
            ];
          }
          return [
            ...filterObjs,
            {
              property: key,
              operator: 'eq',
              value: params.filters[key],
            },
          ];
        }, []);

        if (filters.length) {
          queryString.append('filter', JSON.stringify(filters));
        }
        // Include sort method
        const sortValue = Object.values(sort).join(',');
        queryString.append('sort', sortValue);

        if (coordinates?.x) {
          // We round the user's coordinates when we fetch this data
          queryString.append('latitude', round(coordinates?.x)?.toString());
          queryString.append('longitude', round(coordinates?.y)?.toString());
        }
        queryString.append('page', page.toString());

        // If the user is doing a location-based search, we round the coordinates and buffer them, so
        // we can't be certain the pagination is as we'd expect, so we simply retrieve essentially
        // all of them (there should never be more than 1,000 items at a single location)
        //
        // Also, if we're sorting based on proximity to the user's current location, we also do some
        // precision reduction there, so we want to retrieve 1.5 pages and re-sort based on the
        // user's actual location, which should provide accurate data, at least for the closest few
        // items
        //
        // Otherwise we use the regular page number
        queryString.append(
          'size',
          params.filters['searchGeom'] ? '1000' : size.toString(),
        );
        const response: { error?: FetchBaseQueryError; data?: unknown } =
          await baseQuery({
            url: `${process.env.REACT_APP_GP_ADMIN_URL}/api/alertslist/${
              params.groupedAlerts ? 'groupincident' : 'alerts'
            }?${queryString.toString()}`,
            method: 'POST',
          });
        if (response.error?.status === 404) {
          // don't refetch on 404
          return { data: response.data };
        }
        if (response.error) {
          // but refetch on another error
          return { error: response.error };
        }

        if (params?.groupedAlerts) {
          //if we are calling alertslist/groupincident then -
          // - we should process the `GroupAlertDetails` type to `AlertCardData[]`
          (response.data as { content: AlertCardData[] }).content = (
            response?.data as GroupedAlertDetails
          )?.content?.map(alertGroup => transformToAlertCardData(alertGroup));
        }
        // If we searched for geometry
        if (params.filters['searchGeom']) {
          // Filter out unmatching items based on the actual searched geometry, since we reduced the
          // precision when we sent the network request
          (response.data as { content: AlertCardData[] }).content =
            filterResponseData(
              (response.data as { content: AlertCardData[] }).content,
              polygon([
                [
                  [
                    params.filters['searchGeom'].xmax,
                    params.filters['searchGeom'].ymax,
                  ],
                  [
                    params.filters['searchGeom'].xmax,
                    params.filters['searchGeom'].ymin,
                  ],
                  [
                    params.filters['searchGeom'].xmin,
                    params.filters['searchGeom'].ymin,
                  ],
                  [
                    params.filters['searchGeom'].xmin,
                    params.filters['searchGeom'].ymax,
                  ],
                  [
                    params.filters['searchGeom'].xmax,
                    params.filters['searchGeom'].ymax,
                  ],
                ],
              ]),
            );
        }
        // If we're sorting based on the user's location, we reduced that precision, so need to re
        // sort it again
        if (coordinates?.x) {
          // Sort the response data based on distance to that point
          (response.data as { content: AlertCardData[] }).content =
            reSortResponseData(
              (response.data as { content: AlertCardData[] }).content,
              [coordinates.x, coordinates?.y],
            );
        }
        return { data: response.data };
      },
    }),
    fetchMapDataUpdates: builder.query<{ layersToUpdate: string[] }, void>({
      queryFn: () => ({ data: { layersToUpdate: null } }),
      async onCacheEntryAdded(
        _,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        try {
          await cacheDataLoaded;

          const invalidateTagWithTimeout = tag => {
            setTimeout(
              () => dispatch(ZonesApi.util.invalidateTags([tag])),
              CacheTimeoutPeriod,
            );
          };

          const listener = (event: MessageEvent) => {
            if (event.data === 'pong') {
              return;
            }

            const data = JSON.parse(event.data) as {
              statusUpdate: boolean;
              arrivalPointUpdate: boolean;
              tcpUpdate: boolean;
              splitMergeUpdate: boolean;
            };

            if (data.statusUpdate || data.splitMergeUpdate) {
              invalidateTagWithTimeout(ZonesApiCacheTags.ZoneDetails);
            }

            if (data.tcpUpdate) {
              invalidateTagWithTimeout(ZonesApiCacheTags.TrafficControlPoints);
            }

            if (data.arrivalPointUpdate) {
              invalidateTagWithTimeout(ZonesApiCacheTags.EvacuationPoints);
            }

            updateCachedData(draft => {
              draft = { layersToUpdate: Object.keys(data) };
              return draft;
            });
          };

          let intervalId: ReturnType<typeof setInterval> | null = null;
          let isAlive = false;

          const connectWs = () => {
            if (isAlive) {
              return;
            }

            const ws = new WebSocket(
              `${process.env.REACT_APP_ZONE_STATUS_UPDATES_WEBSOCKET}?type=CEI`,
            );

            ws.onopen = () => {
              isAlive = true;

              const PintIntervalInMinutes = 9;
              const PingIntervalInMilliseconds =
                PintIntervalInMinutes * 60 * 1000;

              intervalId = setInterval(() => {
                ws.send('ping');
              }, PingIntervalInMilliseconds);
            };

            ws.onmessage = listener;

            ws.onclose = () => {
              // eslint-disable-next-line no-console
              console.warn('---- Websocket closed ----');

              if (intervalId) {
                clearInterval(intervalId);
              }

              isAlive = false;
            };

            ws.onerror = error => {
              // eslint-disable-next-line no-console
              console.warn('---- Websocket error ----', error);

              isAlive = false;
            };
          };

          window.addEventListener('online', connectWs);

          connectWs();
        } catch (error) {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded` (in which case `cacheDataLoaded` will throw)
          console.warn(
            'Something went wrong connecting to the websocket. Error:',
            error,
          );
        }

        await cacheEntryRemoved;
      },
    }),
    fetchZonesByRadius: builder.query<
      any,
      {
        coordinate: { lat: number; lon: number };
        bufferInKm: number;
      }
    >({
      providesTags: [ZonesApiCacheTags.EvacFetchZone],
      query: ({ coordinate, bufferInKm = 1 }) => {
        const bbox = getBboxSquareAroundCoordinate({
          coordinate,
          bufferInKm,
        });
        return `zones?bbox=${bbox.join(',')}&simplify_geom=high`;
      },
    }),
  }),
});

export const {
  useFetchHazardsQuery,
  useFetchWeatherQuery,
  useFetchZoneLinksQuery,
  useFetchZoneDetailsQuery,
  useFetchZoneDetailsWithCoordsQuery,
  useFetchHazardDetailsQuery,
  useFetchZoneTrafficControlPointsQuery,
  useFetchMapDataUpdatesQuery,
  useFetchZoneEvacuationPointsQuery,
  useFetchAlertsQuery,
  useFetchZonesByRadiusQuery,
  useFetchLocationTrafficControlPointsQuery,
  useFetchLocationEvacuationPointsQuery,
} = ZonesApi;
