import { Box, Tooltip, Typography } from '@mui/material';

import Icon from '~/components/Icon';
import { AlertCardData } from '~/pages/AlertsList/types';
import { theme } from '~/theme';
import { PropsWithSx } from '~/utils';
import {
  getAlertColor,
  getDefaultBrowserLanguage,
  getTranslatedText,
} from '~/utils/alertsUtils';

import { AsyncMapCard } from '../AsyncMapCard';

interface AlertCardProps {
  sx?: any;
  onClose?: () => void;
  alertDetails: AlertCardData;
}

export const AlertCardPopup = ({
  sx,
  onClose,
  alertDetails,
}: PropsWithSx<AlertCardProps>) => {
  const getAlertStatus = () => {
    return alertDetails?.status
      ? alertDetails?.status?.description
      : getTranslatedText(alertDetails?.titles, getDefaultBrowserLanguage());
  };

  const getAlertDescription = () => {
    return alertDetails?.statusReason
      ? alertDetails.statusReason
      : getTranslatedText(
          alertDetails?.descriptions,
          getDefaultBrowserLanguage(),
        );
  };

  return (
    <AsyncMapCard
      onClose={() => {
        onClose();
      }}
      closeButtonLabel={`Close Zone ${alertDetails?.status}`}
      sx={{ ...sx, maxWidth: '380px' }}
    >
      <Box
        sx={{
          display: 'flex',
          borderRadius: 1,
          flexDirection: 'row',
        }}
      >
        <Icon
          size={20}
          color={getAlertColor(alertDetails)}
          name={'StatusDot'}
        />
        <Typography
          variant="body1"
          sx={{
            maxWidth: '310px',
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            pl: 1,
          }}
        >
          {getAlertStatus()}
        </Typography>
      </Box>
      <Box>
        <Tooltip title={getAlertDescription()}>
          <Typography
            variant="body2"
            sx={{
              marginTop: 1,
              maxWidth: '380px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {getAlertDescription()}
          </Typography>
        </Tooltip>
      </Box>
    </AsyncMapCard>
  );
};
