import { Box, useTheme } from '@mui/material';
import { center as turfGetCenter, flip } from '@turf/turf';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';

import { AlertColor } from '~/components/Map/config';
import { Loader } from '~/components/UI';
import { Color } from '~/constants';
import { AlertActions, useAppDispatch, useAppSelector } from '~/redux-rtk';
import { AppActions, LocationSelectionType } from '~/redux-rtk/slices/appSlice';
import { isRehydrated } from '~/redux-rtk/store';
import {
  convertWktToGeoJson,
  getAlertColor,
  getDefaultBrowserLanguage,
  getNativeLanguageNames,
} from '~/utils/alertsUtils';

import AlertDetails from './AlertDetails';

interface SelectDataType {
  value: string;
  label: string;
}

const AlertDetailView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const [availableLanguages, setAvailableLanguages] = useState<
    SelectDataType[]
  >([]);
  const [mapLoading, setMapLoading] = useState(false);

  const { map, alertDetailsPreview } = useMap();

  const mapContainer = map || alertDetailsPreview;

  const alertData = useAppSelector(state => state.alert.selectedAlertData);
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  useEffect(() => {
    if (alertData) {
      const languages = getNativeLanguageNames(
        alertData?.titles.map(title => title.id),
      );
      i18n.changeLanguage(
        languages[0]?.languageCode || getDefaultBrowserLanguage(),
      );
      setAvailableLanguages(
        languages.map(item => ({
          value: item.languageCode,
          label: item.displayName,
        })),
      );
    } else {
      navigate('/alerts');
    }
    return () => {
      if (isRehydrated) {
        dispatch(AlertActions.updateSelectedAlertData(null));
      }
    };
  }, []);

  const addLayerToMap = () => {
    if (!alertData?.active) {
      mapContainer.getMap().addSource('alert-geojson', {
        type: 'geojson',
        data: convertWktToGeoJson(alertData?.geom),
      });
      mapContainer.getMap().addLayer({
        id: 'alert_polygon_geom',
        type: 'fill',
        source: 'alert-geojson',
        minzoom: 0,
        maxzoom: 22,
        paint: {
          'fill-color': `${getAlertColor(alertData)}`,
          'fill-opacity': 0.5,
          'fill-outline-color': `${getAlertColor(alertData)}`,
        },
      });
      mapContainer.getMap().addLayer({
        id: 'alert_polygon_highlighted_geom',
        type: 'line',
        source: 'alert-geojson',
        paint: {
          'line-opacity': 0.7,
          'line-width': 4.5,
          'line-color':
            alertData.source === 'EVAC' ? Color.LeafGreen : AlertColor,
        },
      });
    }
    const pointCenter = turfGetCenter(
      convertWktToGeoJson(alertData?.geom) as any,
    );

    // we need the coordintes in the form 'y,x'
    const center = flip(pointCenter);

    // 'goto location' is handled in src/components/Map/Map.tsx
    dispatch(
      AppActions.setInitialLocation({
        type: LocationSelectionType.Alert,
        position: center.geometry.coordinates,
        zoom: 14,
        settings: {
          hasFlyToAnimation: true,
        },
        id: alertData.campaign_id ?? selectedLocation?.id,
        zoneId: selectedLocation?.zoneId ?? selectedLocation?.id,
        prevPosition:
          selectedLocation?.prevPosition ?? selectedLocation?.position,
        details: {
          zonesImpacted: alertData.zonesImpacted,
        },
      }),
    );
    setMapLoading(false);
  };

  useEffect(() => {
    if (!mapContainer || !alertData?.geom) {
      return;
    }
    if (mapContainer.isStyleLoaded()) {
      addLayerToMap();
    } else {
      mapContainer.once('idle', () => {
        addLayerToMap();
      });
    }

    // Cleanup
    return () => {
      mapContainer.off('idle', addLayerToMap);
      if (!alertData?.active && mapContainer?.getMap?.()) {
        try {
          const map = mapContainer.getMap();
          if (map.getLayer('alert_polygon_geom')) {
            map.removeLayer('alert_polygon_geom');
          }
          if (map.getLayer('alert_polygon_highlighted_geom')) {
            map.removeLayer('alert_polygon_highlighted_geom');
          }
          if (map.getSource('alert-geojson')) {
            map.removeSource('alert-geojson');
          }
        } catch (err) {
          console.error('Cleanup error:', err);
        }
      }
    };
  }, [alertData, mapContainer]);

  return mapLoading ? (
    <Box
      sx={{
        marginTop: theme.spacing(20),
      }}
    >
      <Loader />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <AlertDetails
        alertDetails={alertData}
        availableLanguages={availableLanguages}
      />
    </Box>
  );
};

export default AlertDetailView;
