/* eslint-disable complexity */
import { Box } from '@mui/material';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useCallback, useEffect, useMemo } from 'react';

import { PointOfInterest } from './PointOfInterest';
import RoadClosureIcon from '../../../assets/icons/road-closures.png';
import TrafficControlPointIcon from '../../../assets/icons/traffic-control-points.png';
import { I18n } from '../../../i18n';
import { Card } from '../../../layouts/Card';
import {
  useAppDispatch,
  useAppSelector,
  useFetchLocationEvacuationPointsQuery,
  useFetchLocationTrafficControlPointsQuery,
  useFetchZoneEvacuationPointsQuery,
  useFetchZoneTrafficControlPointsQuery,
} from '../../../redux-rtk';
import {
  AppActions,
  ArrivalPointTypeMapping,
  LocationSelectionType,
} from '../../../redux-rtk/slices/appSlice';
import { Divider } from '../../Divider';
import { Transition } from '../../Transition';
import { Loader } from '../../UI';
import { Icon } from '../../UI/Icon';
import { Title } from '../shared/Title';
import { H2Title } from '../shared/Title/H2Title';
import { getBboxSquareAroundCoordinate } from '~/utils/alertsUtils';
import { round } from '~/utils';

interface Props {
  zoneId?: string;
  location?: any;
  skipApiCall?: boolean;
}

//7 miles radius in km = 11.26 + 1km error
const CRITICAL_RADIUS = 12.26;

export const PointsOfInterestContainer = ({
  zoneId,
  location,
  skipApiCall,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );
  const evacuationPointsUsingZone = useFetchZoneEvacuationPointsQuery(zoneId, {
    refetchOnMountOrArgChange: true,
    skip: !zoneId,
  });
  const trafficControlPointsUsingZone = useFetchZoneTrafficControlPointsQuery(
    zoneId,
    {
      refetchOnMountOrArgChange: true,
      skip: !zoneId,
    },
  );

  const bufferedLocation = useMemo(
    () =>
      location
        ? getBboxSquareAroundCoordinate({
            coordinate: { lon: round(location[0]), lat: round(location[1]) },
            bufferInKm: CRITICAL_RADIUS,
          }).map(coordinate => round(coordinate))
        : null,
    [location],
  );

  //in location selection page we will use selectedLocation instead of zoneId
  //but critical points etc shouldn't affect this
  const evacuationPointsInLocation = useFetchLocationEvacuationPointsQuery(
    bufferedLocation,
    {
      refetchOnMountOrArgChange: true,
      skip: skipApiCall || !bufferedLocation,
    },
  );
  const trafficControlPointsInLocation =
    useFetchLocationTrafficControlPointsQuery(bufferedLocation, {
      refetchOnMountOrArgChange: true,
      skip: skipApiCall || !bufferedLocation,
    });

  const trafficControlPointsResponse = useMemo(() => {
    return zoneId
      ? trafficControlPointsUsingZone
      : trafficControlPointsInLocation;
  }, [trafficControlPointsUsingZone, trafficControlPointsInLocation, zoneId]);

  const evacuationPointsResponse = useMemo(() => {
    return zoneId ? evacuationPointsUsingZone : evacuationPointsInLocation;
  }, [evacuationPointsUsingZone, evacuationPointsInLocation, zoneId]);

  const handleOnViewPointOfInterest = useCallback(
    (pointOfInterest, type: LocationSelectionType) => {
      const { latitude, longitude, id, name, address, tcpNote } =
        pointOfInterest;

      const details = {
        id,
        name,
        address,
        type,
        description: tcpNote ? stateToHTML(convertFromRaw(tcpNote)) : '',
      };

      return dispatch(
        AppActions.selectLocation({
          id,
          type,
          details,
          position: [latitude, longitude],
          prevPosition:
            selectedLocation?.prevPosition ?? selectedLocation?.position,
          zoneId: selectedLocation?.zoneId ?? selectedLocation?.id,
        }),
      );
    },
    [dispatch],
  );
  const trafficControlPoints =
    trafficControlPointsResponse?.data?.trafficControlPoints || [];
  const evacuationPoints = evacuationPointsResponse?.data?.arrivalPoints || [];

  const hideComponent =
    !evacuationPointsResponse?.isLoading &&
    !trafficControlPointsResponse?.isLoading &&
    evacuationPoints.length === 0 &&
    trafficControlPoints.length === 0;

  return (
    <Transition in={!hideComponent} mountOnEnter>
      <Card hasShadow={false} backgroundColor="grey">
        <H2Title title={I18n.t('zoneDetails.criticalPoints')} />
        {(evacuationPoints.length > 0 ||
          evacuationPointsResponse?.isLoading) && (
          <>
            <Title
              title={I18n.t('zoneDetails.evacuationPoints')}
              icon={
                <Icon
                  size={18}
                  name={'evacuation-points'}
                  isDecorative={true}
                />
              }
            />

            <Box
              sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              {evacuationPointsResponse?.isLoading ? (
                <Loader />
              ) : evacuationPoints.length === 0 ? (
                <div className="no-pois-container">
                  <div className="no-pois-text">
                    {I18n.t('zoneDetails.noEvacuationPoints')}
                  </div>
                </div>
              ) : (
                evacuationPoints?.map((el, index) => (
                  <PointOfInterest
                    key={index}
                    title={el.name}
                    description={el.address}
                    onView={() =>
                      handleOnViewPointOfInterest(
                        el,
                        ArrivalPointTypeMapping[el.type],
                      )
                    }
                  />
                ))
              )}
            </Box>
          </>
        )}

        {(trafficControlPoints.length > 0 ||
          trafficControlPointsResponse?.isLoading) && (
          <>
            {(evacuationPoints.length > 0 ||
              evacuationPointsResponse?.isLoading) && (
              <Divider sx={{ my: 3 }} />
            )}

            <Title
              title={I18n.t('zoneDetails.trafficControlPoints')}
              icon={
                <Icon size={20} name={'road-closures'} isDecorative={true} />
              }
            />

            <Box
              sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              {trafficControlPointsResponse?.isLoading ? (
                <Loader />
              ) : trafficControlPoints.length === 0 ? (
                <div className="no-pois-container">
                  <div className="no-pois-text">
                    {I18n.t('zoneDetails.noTrafficControlPoints')}
                  </div>
                </div>
              ) : (
                trafficControlPoints?.map((el, index) => (
                  <PointOfInterest
                    key={index}
                    title={el.name}
                    description={el.address}
                    iconSrc={
                      el.type === LocationSelectionType.TrafficControlPoint
                        ? TrafficControlPointIcon
                        : RoadClosureIcon
                    }
                    onView={() => handleOnViewPointOfInterest(el, el.type)}
                  />
                ))
              )}
            </Box>
          </>
        )}
      </Card>
    </Transition>
  );
};
