import { SxProps, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { getAddressFromLocation } from '~/api';
import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { useLocationShouldHaveAddress } from '~/hooks/useLocation';
import { useAppSelector } from '~/redux-rtk/';
import { theme } from '~/theme';
import { getZoneIdFromZoneName, PropsWithSx } from '~/utils';

import { I18n } from '../../i18n/i18n';
import { BaseMapCard } from '../Map/MapCards/BaseMapCard';

interface Props {
  zoneId?: number | string;
  isCurrentLocation?: boolean;
  hideTitle?: boolean;
  currentLocationIcon?: boolean;
  sx?: SxProps;
  greyBackground?: boolean;
  onClose?: () => void;
}

export const SelectedLocation = ({
  zoneId,
  hideTitle,
  isCurrentLocation,
  currentLocationIcon,
  greyBackground,
  onClose,
}: PropsWithSx<Props>) => {
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const userLocation = useAppSelector(state => state.user.data.currentLocation);
  const [loading, setLoading] = useState(false);

  const locationShouldHaveAddress =
    useLocationShouldHaveAddress(isCurrentLocation);

  const [selectedAddress, setSelectedAddress] = useState('');
  useEffect(() => {
    if (!locationShouldHaveAddress) {
      // Return early if we have no valid location data
      return;
    }
    setLoading(true);
    const locationCoordinates = isCurrentLocation
      ? (userLocation.coordinates as [number, number])
      : (selectedLocation?.prevPosition as [number, number]) ??
        (selectedLocation?.position as [number, number]);

    const locationZoom = selectedLocation?.zoom;

    getAddressFromLocation(locationCoordinates, locationZoom)
      .then(response => {
        setLoading(false);
        if (response?.address) {
          setSelectedAddress(response.address?.LongLabel);
        }
      })
      .catch(err => setLoading(false));
  }, [
    selectedLocation,
    userLocation,
    selectedLocation?.type,
    isCurrentLocation,
    locationShouldHaveAddress,
  ]);

  const iconTitle = useMemo(
    () =>
      hideTitle
        ? null
        : isCurrentLocation
        ? I18n.t('myZones.myCurrentLocation')
        : I18n.t('myZones.selectedLocation'),
    [hideTitle, isCurrentLocation],
  );

  return (
    <BaseMapCard
      Icon={
        <Icon
          name={currentLocationIcon ? 'Location' : 'LocationShow'}
          size={25}
          color={Color.DarkBlue}
        ></Icon>
      }
      iconTitle={iconTitle}
      onClose={onClose}
      isCloseButtonVisible={Boolean(onClose)}
      sx={
        greyBackground
          ? {
              backgroundColor: Color.Grey50,
              borderRadius: theme.spacing(1),
              margin: theme.spacing(0, 0, 1, 0),
              padding: theme.spacing(2),
            }
          : {
              padding: theme.spacing(1, 2),
            }
      }
      closeButtonLabel="Close"
      isFetching={loading}
    >
      <Tooltip title={selectedAddress}>
        <Typography
          variant="body1"
          sx={{ marginLeft: '.3em', maxWidth: theme.spacing(40) }}
        >
          {selectedAddress}
        </Typography>
      </Tooltip>

      {zoneId && (
        <Typography variant="body1" sx={{ marginLeft: '.3em' }}>
          <b>{I18n.t('myZones.zoneId')}</b>:{' '}
          {getZoneIdFromZoneName(String(zoneId))}
        </Typography>
      )}
    </BaseMapCard>
  );
};
